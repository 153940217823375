// Corresponding constants file in backend /lambda/utils/certificatePdfProcess/config/config.js
const errors = {
  INVALID_PDF: 'invalid_pdf',
  FILE_NOT_PDF: 'file_not_pdf',
  VALIDATION_NOT_RUN: 'file_not_pdf',
  NO_FILES: 'no_files',
  LEGACY_CERTIFICATE: 'legacy_certificate',
  UNKNOWN_CERTIFICATE_TYPE: 'unknown_certificate_type',
  IS_BOT: 'request_is_bot',
  MODULE_REPORT_PDF: 'module_report',
  DATE_TAKEN_BEFORE_JULY_2023: 'date_taken_before_july_2023'
};

export default { errors };
